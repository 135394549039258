import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faBitcoin, faPaypal } from '@fortawesome/free-brands-svg-icons';
import { faCopy, faCreditCard, faMoneyBillWave, faUniversity } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

const AccountsPage = () => {
  const [accounts, setAccounts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await fetch('https://api.wildismcoins.net/account');
        const data = await response.json();
        if (response.status === 200) {
          setAccounts(data);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };

    fetchAccounts();
  }, []);

  const copyLinkToClipboard = (accountNumber) => {
    const link = `https://wildismcoins.net/accounts/${accountNumber}`;
    navigator.clipboard.writeText(link);
  };

  const getPaymentIcon = (method) => {
    switch (method.toLowerCase()) {
      case 'paypal': return faPaypal;
      case 'crypto': return faBitcoin;
      case 'credit card': return faCreditCard;
      case 'bank transfer': return faUniversity;
      case 'cash app': return faMoneyBillWave;
      case 'apple cash': return faApple;
      default: return faCreditCard;
    }
  };

  const handleCardClick = (accountNumber) => {
    navigate(`/accounts/${accountNumber}`);
  };

  return (
    <div className="flex flex-col min-h-screen bg-background">
      <Helmet>
        <title>Hypixel Skyblock Accounts for Sale</title>
        <meta name="description" content="Browse through our secure accounts available for sale from our trusted sellers. Find detailed stats and secure payment methods for each account." />
        <meta property="og:title" content="Hypixel Skyblock Accounts for Sale" />
        <meta property="og:description" content="Explore our wide selection of Skyblock accounts for sale from trusted sellers. Secure payment methods and detailed stats available for each account." />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
      </Helmet>
      <Header />

      <main className="container mx-auto px-4 md:px-8 py-12 flex-grow">
        <section className="text-center mb-16">
          <h2 className="text-5xl md:text-6xl font-bold mb-6 text-indigo-400">
            Skyblock Accounts
          </h2>
          <p className="text-xl text-gray-300 mb-10">
            Browse through our secure accounts from trusted sellers
          </p>
        </section>

        <section className="grid md:grid-cols-2 gap-8">
                      {accounts.length === 0 ? (
            <div className="col-span-2 text-center py-16">
              <div className="bg-primary/20 backdrop-blur-sm rounded-2xl p-8 border border-primary/30 shadow-xl">
                <h3 className="text-2xl font-bold text-accent mb-4">No Accounts Available</h3>
                <p className="text-gray-300">Check back later for new account listings</p>
              </div>
            </div> 
            ) : ( 
            <div></div>
            )}
          {accounts.map((account, index) => (
            <div
              key={index}
              className="bg-primary/20 backdrop-blur-sm rounded-2xl p-8 border border-primary/30 shadow-xl cursor-pointer transform hover:scale-[1.02] transition-all duration-300"
              onClick={() => handleCardClick(account.accountNumber)}
            >
              <div className="space-y-6">
                <div className="flex justify-between items-center">
                  <div className="text-2xl font-bold text-accent">
                    {formatRank(account.stats.rank)}
                  </div>
                  <div className="text-2xl font-bold text-accent">
                    ${account.price}
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-4 text-gray-200">
                  <div>
                    <span className="text-gray-400">Level</span>
                    <div className="font-semibold">{account.stats.sblevel.toFixed(2)}</div>
                  </div>
                  <div>
                    <span className="text-gray-400">Skill Avg</span>
                    <div className="font-semibold">{account.stats.skillaverage.toFixed(2)}</div>
                  </div>
                  <div>
                    <span className="text-gray-400">Slayer</span>
                    <div className="font-semibold">{`${account.stats.slayers.zombie}/${account.stats.slayers.spider}/${account.stats.slayers.wolf}/${account.stats.slayers.enderman}`}</div>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4 text-gray-200">
                  <div>
                    <span className="text-gray-400">Networth</span>
                    <div className="font-semibold">Total: {account.stats.networth.total}</div>
                    <div className="font-semibold">Unsoulbound: {account.stats.networth.unsoulbound}</div>
                  </div>
                  <div>
                    <span className="text-gray-400">Weight</span>
                    <div className="font-semibold">Senither: {account.stats.weight.senither}</div>
                    <div className="font-semibold">Lily: {account.stats.weight.lily}</div>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4 text-gray-200">
                  <div>
                    <span className="text-gray-400">Mining</span>
                    <div className="font-semibold">HOTM: {account.stats.mining.hotm}</div>
                    <div className="font-semibold">Powder: {account.stats.mining.mithril}/{account.stats.mining.gemstone}</div>
                  </div>
                  <div>
                    <span className="text-gray-400">Dungeons</span>
                    <div className="font-semibold">Cata: {account.stats.dungeons.level}</div>
                    <div className="font-semibold">Class Avg: {account.stats.dungeons.average.toFixed(2)}</div>
                  </div>
                </div>

                <div className="flex flex-wrap gap-2">
                  {account.paymentMethods.map((method, methodIndex) => (
                    <span key={methodIndex} className="bg-secondary/40 text-gray-200 py-2 px-3 rounded-xl flex items-center text-sm">
                      <FontAwesomeIcon icon={getPaymentIcon(method)} className="mr-2" />
                      {method}
                    </span>
                  ))}
                </div>

                <div className="flex justify-between gap-4">
                  <button
                    className="flex-1 bg-accent hover:bg-accent-dark text-white py-3 px-6 rounded-xl text-lg font-semibold transform hover:scale-105 transition-all duration-200 shadow-lg shadow-accent/25"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCardClick(account.accountNumber);
                    }}
                  >
                    Buy Account
                  </button>
                  <button
                    className="bg-secondary/40 hover:bg-secondary/60 text-white py-3 px-6 rounded-xl text-lg flex items-center justify-center transform hover:scale-105 transition-all duration-200"
                    onClick={(e) => {
                      e.stopPropagation();
                      copyLinkToClipboard(account.accountNumber);
                    }}
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </section>
      </main>
      <Footer />
    </div>
  );
};

const formatRank = (rank) => {
  switch (rank) {
    case '[NON]':
      return (
        <span style={{ color: '#AAAAAA' }}>
          <span style={{ color: '#FFFFFF' }}>[</span>
          NON
          <span style={{ color: '#FFFFFF' }}>]</span>
        </span>
      );
    case '[VIP]':
      return (
        <span style={{ color: '#55FF55' }}>
          <span style={{ color: '#FFFFFF' }}>[</span>
          VIP
          <span style={{ color: '#FFFFFF' }}>]</span>
        </span>
      );
    case '[VIP+]':
      return (
        <span style={{ color: '#55FF55' }}>
          <span style={{ color: '#FFFFFF' }}>[</span>
          VIP
          <span style={{ color: '#FFFFFF' }}>+</span>
          <span style={{ color: '#FFFFFF' }}>]</span>
        </span>
      );
    case '[MVP]':
      return (
        <span style={{ color: '#55FFFF' }}>
          <span style={{ color: '#FFFFFF' }}>[</span>
          MVP
          <span style={{ color: '#FFFFFF' }}>]</span>
        </span>
      );
    case '[MVP+]':
      return (
        <span style={{ color: '#55FFFF' }}>
          <span style={{ color: '#FFFFFF' }}>[</span>
          MVP
          <span style={{ color: '#FFFFFF' }}>+</span>
          <span style={{ color: '#FFFFFF' }}>]</span>
        </span>
      );
    case '[MVP++]':
      return (
        <span style={{ color: '#FFAA00' }}>
          <span style={{ color: '#FFFFFF' }}>[</span>
          MVP
          <span style={{ color: '#FFFFFF' }}>+</span>
          <span style={{ color: '#FFFFFF' }}>+</span>
          <span style={{ color: '#FFFFFF' }}>]</span>
        </span>
      );
    case '[YOUTUBE]':
      return (
        <span style={{ color: '#FF5555' }}>
          <span style={{ color: '#FFFFFF' }}>[</span>
          YOUTUBE
          <span style={{ color: '#FFFFFF' }}>]</span>
        </span>
      );
    default:
      return <span style={{ color: '#AAAAAA' }}>{rank}</span>;
  }
};

export default AccountsPage;
