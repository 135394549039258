import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import { FaDiscord } from 'react-icons/fa';

const BuyPage = () => {
  const [coinsToBuy, setCoinsToBuy] = useState(250000000);
  const [inputValue, setInputValue] = useState('250m');
  const [ign, setIGN] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [buyPriceUnder400m, setBuyPriceUnder400m] = useState(0.075);
  const [buyPriceUnder1b, setBuyPriceUnder1b] = useState(0.07);
  const [buyPriceOver1b, setBuyPriceOver1b] = useState(0.065);
  const [reportStatus, setReportStatus] = useState('');
  const [isReporting, setIsReporting] = useState(false);

  useEffect(() => {
    fetch('https://api.wildismcoins.net/prices')
      .then(response => response.json())
      .then(data => {
        if (data.buy) {
          setBuyPriceUnder400m(data.buy.buyPriceUnder400m || 0.075);
          setBuyPriceUnder1b(data.buy.buyPriceUnder1b || 0.07);
          setBuyPriceOver1b(data.buy.buyPriceOver1b || 0.065);
        }
      })
      .catch(() => setReportStatus('Error fetching prices'));
  }, []);

  const calculatePrice = () => {
    const millions = coinsToBuy / 1000000;
    if (millions <= 400) return millions * buyPriceUnder400m;
    if (millions <= 1000) return millions * buyPriceUnder1b;
    return millions * buyPriceOver1b;
  };

  const handleSliderChange = (e) => {
    const value = parseInt(e.target.value);
    setCoinsToBuy(value);
    setInputValue(formatCoinsInput(value));
  };

  const handleCoinsInputChange = (e) => {
    const value = e.target.value.toLowerCase();
    setInputValue(value);
    
    let numericValue = parseFloat(value);
    if (isNaN(numericValue)) {
      setCoinsToBuy(250000000);
      return;
    }

    if (value.endsWith('b')) numericValue *= 1000000000;
    else if (value.endsWith('m')) numericValue *= 1000000;
    else numericValue *= 1000000;

    setCoinsToBuy(numericValue);
  };

  const formatCoinsInput = (value) => {
    if (value >= 1000000000) return `${(value / 1000000000).toFixed(3)}b`;
    return `${(value / 1000000).toFixed(0)}m`;
  };

  const handleSubmit = async () => {
    if (!ign || !paymentMethod) {
      setReportStatus('Please fill in all required fields');
      return;
    }

    setIsReporting(true);
    try {
      const response = await fetch('https://api.wildismcoins.net/ticket', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
          type: 'buy',
          value: coinsToBuy.toString(),
          ign,
          paymentMethod
        })
      });
      
      const data = await response.json();
      setReportStatus(data.status === 'OK' ? data.message : `Error: ${data.message}`);
      
      if (data.status === 'OK') {
        setCoinsToBuy(250000000);
        setInputValue('250m');
        setIGN('');
        setPaymentMethod('');
      }
    } catch (error) {
      setReportStatus('Error creating ticket');
    }
    setIsReporting(false);
  };

  const hasUserCookie = document.cookie.includes('user=');

  return (
    <div className="flex flex-col min-h-screen bg-background">
      <Header />

      <main className="container mx-auto px-4 md:px-8 py-12 flex-grow">
        <section className="text-center mb-16">
          <h2 className="text-5xl md:text-6xl font-bold mb-6 text-indigo-400">
            Buying Coins
          </h2>
          <p className="text-lg md:text-xl text-gray-300 mb-10">
            Find and purchase coins from our trusted sellers
          </p>

          {!hasUserCookie && (
            <div className="bg-gray-900/50 backdrop-blur-sm rounded-2xl p-6 md:p-8 border border-indigo-500/20">
              <p className="text-gray-300 text-lg mb-8 text-center">
                Connect with Discord for a seamless experience. We'll automatically set up your account and create your ticket.
              </p>
              <div className="flex flex-col items-center gap-8">
                <a
                  href="https://discord.com/oauth2/authorize?client_id=1281947269284761660&redirect_uri=https%3A%2F%2Fapi.wildismcoins.net%2Fcallback&response_type=code&scope=identify%20guilds.join"
                  className="bg-[#5865F2] hover:bg-[#4752C4] text-white py-3 px-8 rounded-xl text-lg font-semibold flex items-center gap-3 transform hover:scale-105 transition-all duration-200 shadow-lg"
                >
                  <FaDiscord size={24} />
                  Login with Discord
                </a>
                <div className="w-full text-center">
                  <p className="text-gray-300 text-lg mb-4">
                    Alternatively, join our Discord server directly:
                  </p>
                  <a
                    href="https://discord.gg/fmPqM4MWA2"
                    className="bg-gray-800 hover:bg-gray-700 text-white py-3 px-8 rounded-xl text-lg font-semibold inline-flex items-center gap-3 transform hover:scale-105 transition-all duration-200 shadow-lg"
                  >
                    <FaDiscord size={24} />
                    Join Official Discord Server
                  </a>
                </div>
              </div>
            </div>
          )}
        </section>

        <section className="grid gap-8">
          <div className="bg-gray-900/50 backdrop-blur-sm rounded-2xl p-6 md:p-8 border border-indigo-500/20">
            <h3 className="text-3xl md:text-4xl font-bold mb-8 text-indigo-400">Price Calculator</h3>
            <div className="space-y-6">
              <input
                type="range"
                min={250000000}
                max={20000000000}
                step={50000000}
                value={coinsToBuy}
                onChange={handleSliderChange}
                className="w-full h-2 rounded-lg appearance-none cursor-pointer bg-gray-700"
              />
              <div className="flex justify-between text-gray-300 text-sm md:text-lg">
                <span>250M</span>
                <span>20B</span>
              </div>
              <div className="relative">
                <input
                  type="text"
                  value={inputValue}
                  onChange={handleCoinsInputChange}
                  className="w-full bg-gray-800 text-white py-3 px-4 rounded-xl text-center text-lg border border-gray-700 focus:border-indigo-500 outline-none"
                />
              </div>
              <div className="space-y-2 p-4 bg-gray-800/50 rounded-lg">
                <p className="text-gray-300 text-lg">Selected Amount: <span className="text-indigo-400">{coinsToBuy.toLocaleString()}</span></p>
                <p className="text-gray-300 text-lg">Price: <span className="text-indigo-400">${calculatePrice().toLocaleString(undefined, { minimumFractionDigits: 2 })}</span></p>
              </div>
            </div>
          </div>

          {hasUserCookie && (
            <div className="space-y-8">
              <div className="bg-gray-900/50 backdrop-blur-sm rounded-2xl p-6 md:p-8 border border-indigo-500/20">
                <h3 className="text-3xl font-bold mb-6 text-indigo-400">Purchase Details</h3>
                <div className="space-y-6">
                  <div>
                    <label className="block text-gray-300 mb-2">In-Game Name (IGN)</label>
                    <input
                      type="text"
                      placeholder="Enter your IGN"
                      value={ign}
                      onChange={(e) => setIGN(e.target.value)}
                      className="w-full bg-gray-800 text-white py-4 px-6 rounded-xl text-lg border border-gray-700 focus:border-indigo-500 outline-none transition"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-300 mb-2">Payment Method</label>
                    <select
                      value={paymentMethod}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      className="w-full bg-gray-800 text-white py-4 px-6 rounded-xl text-lg border border-gray-700 focus:border-indigo-500 outline-none transition"
                    >
                      <option value="">Select payment method</option>
                      <option value="paypal">PayPal</option>
                      <option value="bitcoin">Crypto</option>
                      <option value="applecash">Apple Cash</option>
                      <option value="cashapp">CashApp</option>
                      <option value="creditcard">Credit/Debit Card</option>
                      <option value="amazongc">Amazon Gift Cards</option>
                    </select>
                  </div>
                </div>
              </div>

              <button
                onClick={handleSubmit}
                disabled={isReporting}
                className="w-full bg-indigo-600 hover:bg-indigo-700 text-white py-4 px-8 rounded-xl text-lg font-semibold transform hover:scale-105 transition-all duration-200 shadow-lg disabled:opacity-50 disabled:transform-none"
              >
                {isReporting ? 'Processing...' : 'Submit Purchase Request'}
              </button>

              {reportStatus && (
                <p className={`text-lg text-center ${reportStatus.includes('Error') ? 'text-red-400' : 'text-green-400'}`}>
                  {reportStatus}
                </p>
              )}
            </div>
          )}
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default BuyPage;