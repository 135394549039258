import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaDiscord, FaSignOutAlt, FaBars, FaTimes } from 'react-icons/fa';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const getUserCookie = () => {
    const cookies = document.cookie.split('; ');
    for (let cookie of cookies) {
      const [name, value] = cookie.split('=');
      if (name === 'user') {
        try {
          const decodedValue = decodeURIComponent(value);
          let jsonValue = decodedValue.startsWith('j:') ? decodedValue.substr(2) : decodedValue;
          const user = JSON.parse(jsonValue);
          return user?.username ? user : null;
        } catch (error) {
          console.error('Error parsing user cookie:', error);
          return null;
        }
      }
    }
    return null;
  };

  const user = getUserCookie();
  const { id, username, avatar } = user || {};

  const handleLoginWithDiscord = () => {
    window.location.href = 'https://discord.com/oauth2/authorize?client_id=1281947269284761660&redirect_uri=https%3A%2F%2Fapi.wildismcoins.net%2Fcallback&response_type=code&scope=identify%20guilds.join';
  };

  const handleLogout = () => {
    document.cookie = 'user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.wildismcoins.net; secure;';
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.wildismcoins.net; secure;';
    window.location.reload();
  };

  return (
    <header className="sticky top-0 z-50 py-4 px-4 md:px-8 bg-primary/20 backdrop-blur-sm border-b border-indigo-500/20">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <Link to="/" className="text-2xl md:text-4xl font-bold text-indigo-400 hover:opacity-80 transition-opacity">
            Wildism Coins
          </Link>

          {/* Mobile menu button */}
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden p-2 text-gray-300 hover:text-indigo-400"
          >
            {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>

          {/* Desktop navigation */}
          <nav className="hidden md:flex items-center gap-6">
            <Link to="/faq" className="text-lg font-semibold text-gray-300 hover:text-indigo-400 transition-colors">
              FAQ
            </Link>
            <Link to="/tos" className="text-lg font-semibold text-gray-300 hover:text-indigo-400 transition-colors">
              TOS
            </Link>
            
            {user ? (
              <div className="flex items-center gap-3">
                {id && avatar && (
                  <img
                    src={`https://cdn.discordapp.com/avatars/${id}/${avatar}.png`}
                    alt={username}
                    className="h-10 w-10 rounded-xl border-2 border-indigo-500/50"
                  />
                )}
                <span className="text-lg font-semibold text-gray-300">{username}</span>
                <button
                  onClick={handleLogout}
                  className="text-gray-300 hover:text-indigo-400 transition-colors p-2"
                >
                  <FaSignOutAlt size={20} />
                </button>
              </div>
            ) : (
              <button
                onClick={handleLoginWithDiscord}
                className="bg-[#5865F2] hover:bg-[#4752C4] text-white py-2 px-4 rounded-xl text-lg font-semibold flex items-center gap-2 transition-all duration-200"
              >
                <FaDiscord size={20} />
                Login
              </button>
            )}
          </nav>
        </div>

        {/* Mobile menu */}
        <div className={`${isMenuOpen ? 'flex' : 'hidden'} md:hidden flex-col gap-4 mt-4 pb-4`}>
          <Link 
            to="/faq" 
            className="text-lg font-semibold text-gray-300 hover:text-indigo-400 transition-colors"
            onClick={() => setIsMenuOpen(false)}
          >
            FAQ
          </Link>
          <Link 
            to="/tos" 
            className="text-lg font-semibold text-gray-300 hover:text-indigo-400 transition-colors"
            onClick={() => setIsMenuOpen(false)}
          >
            TOS
          </Link>
          
          {user ? (
            <div className="flex items-center justify-between gap-3">
              <div className="flex items-center gap-3">
                {id && avatar && (
                  <img
                    src={`https://cdn.discordapp.com/avatars/${id}/${avatar}.png`}
                    alt={username}
                    className="h-10 w-10 rounded-xl border-2 border-indigo-500/50"
                  />
                )}
                <span className="text-lg font-semibold text-gray-300">{username}</span>
              </div>
              <button
                onClick={handleLogout}
                className="text-gray-300 hover:text-indigo-400 transition-colors p-2"
              >
                <FaSignOutAlt size={20} />
              </button>
            </div>
          ) : (
            <button
              onClick={handleLoginWithDiscord}
              className="bg-[#5865F2] hover:bg-[#4752C4] text-white py-2 px-4 rounded-xl text-lg font-semibold flex items-center gap-2 transition-all duration-200 w-full justify-center"
            >
              <FaDiscord size={20} />
              Login with Discord
            </button>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;