import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="py-6 px-4 md:px-8 bg-primary/20 backdrop-blur-sm border-t border-primary/30">
      <div className="container mx-auto text-center space-y-2">
        <p className="text-gray-300">
          Made by {' '}
          <a 
            href="https://lenny.ie" 
            target="_blank"
            rel="noopener noreferrer"
            className="text-accent hover:text-white transition-colors duration-200"
          >
            lenny.ie
          </a>
          {' '}(contact for dev-work) {' '}
          <span className="text-accent"></span>
        </p>
        <p className="text-gray-400">&copy; {currentYear} Wildism Coins. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;