import React, { useState } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import { FaDiscord } from "react-icons/fa";

const AccountBoosting = () => {
  const [ign, setIGN] = useState('');
  const [service, setService] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [reportStatus, setReportStatus] = useState('');
  const [isReporting, setIsReporting] = useState(false);

  const handleIGNChange = (event) => {
    setIGN(event.target.value);
  };

  const handleServiceChange = (event) => {
    setService(event.target.value);
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleSubmit = () => {
    if (!ign || !service || !paymentMethod) {
      setReportStatus('Please fill in all required fields');
      return;
    }

    setReportStatus('');
    const payload = {
      type: 'account-boost',
      ign: ign,
      service: service,
      paymentMethod: paymentMethod,
    };

    setIsReporting(true);
    fetch('https://api.wildismcoins.net/ticket', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(payload),
    })
      .then((response) => {
        setIsReporting(false);
        return response.json();
      })
      .then((responseData) => {
        if (responseData.status === 'OK') {
          setReportStatus(responseData.message);
        } else {
          setReportStatus(`Error: ${responseData.message}`);
        }
      })
      .catch((error) => {
        setIsReporting(false);
        setReportStatus('Error creating ticket');
      });

    setIGN('');
    setService('');
    setPaymentMethod('');
  };

  const hasUserCookie = document.cookie.includes('user=');

  return (
    <div className="flex flex-col min-h-screen bg-background">
      <Header />

      <main className="container mx-auto px-4 md:px-8 py-12 flex-grow">
        <section className="text-center mb-16">
          <h2 className="text-5xl md:text-6xl font-bold mb-6 text-indigo-400">
            Account Boosting
          </h2>
          <p className="text-xl text-gray-300 mb-10">
            Professional boosting services at competitive rates
          </p>

          <div className="bg-primary/20 backdrop-blur-sm rounded-2xl p-8 border border-primary/30 shadow-xl max-w-3xl mx-auto">
            <p className="text-gray-200 mb-8">
              Account grinding services offered at $4.00/hour
            </p>
            <div className="grid gap-4 text-left text-gray-200">
              <div className="bg-secondary/40 p-4 rounded-xl">
                <span className="font-semibold">Enderman Slayer Level 0-7</span>
                <span className="float-right text-accent">$56</span>
              </div>
              <div className="bg-secondary/40 p-4 rounded-xl">
                <span className="font-semibold">Catacombs 0 → 24</span>
                <span className="float-right text-accent">$53</span>
              </div>
              <div className="bg-secondary/40 p-4 rounded-xl">
                <span className="font-semibold">Rift 7/7 charms</span>
                <span className="float-right text-accent">$72</span>
              </div>
              <div className="bg-secondary/40 p-4 rounded-xl">
                <span className="font-semibold">HOTM 3 → 7</span>
                <span className="float-right text-accent">$84</span>
              </div>
              <div className="bg-secondary/40 p-4 rounded-xl">
                <span className="font-semibold">HOTM 7 → 10</span>
                <span className="float-right text-accent">$114</span>
              </div>
            </div>
          </div>

          {!hasUserCookie && (
            <div className="bg-primary/20 backdrop-blur-sm rounded-2xl p-8 border border-primary/30 shadow-xl mt-12">
              <p className="text-white text-lg mb-8 text-center">
                Connect with Discord for a seamless experience. We'll automatically set up your account and create your ticket.
              </p>
              <div className="flex flex-col items-center gap-8">
                <a
                  href="https://discord.com/oauth2/authorize?client_id=1281947269284761660&redirect_uri=https%3A%2F%2Fapi.wildismcoins.net%2Fcallback&response_type=code&scope=identify%20guilds.join"
                  className="bg-[#5865F2] w-fit hover:bg-[#4752C4] text-white py-3 px-8 rounded-xl text-lg font-semibold flex items-center gap-3 transform hover:scale-105 transition-all duration-200 shadow-lg"
                >
                  <FaDiscord size={24} />
                  Login with Discord
                </a>
                <div className="w-full text-center">
                  <p className="text-white text-lg mb-4">
                    Alternatively, join our Discord server directly:
                  </p>
                  <a
                    href="https://discord.gg/fmPqM4MWA2"
                    className="bg-secondary inline-flex hover:bg-primary text-white py-3 px-8 rounded-xl text-lg font-semibold items-center gap-3 transform hover:scale-105 transition-all duration-200 shadow-lg"
                  >
                    <FaDiscord size={24} />
                    Join Official Discord Server
                  </a>
                </div>
              </div>
            </div>
          )}
        </section>

        {hasUserCookie && (
          <section className="max-w-3xl mx-auto space-y-8">
            <div className="bg-primary/20 backdrop-blur-sm rounded-2xl p-8 border border-primary/30 shadow-xl space-y-6">
              <div>
                <label className="block text-gray-300 mb-2">In-Game Name (IGN)</label>
                <input
                  type="text"
                  placeholder="Enter your IGN"
                  value={ign}
                  onChange={handleIGNChange}
                  className="w-full bg-secondary/40 text-white py-4 px-6 rounded-xl text-lg border border-primary/30 focus:border-accent focus:ring-1 focus:ring-accent outline-none transition"
                />
              </div>

              <div>
                <label className="block text-gray-300 mb-2">Service to Boost</label>
                <input
                  type="text"
                  placeholder="Enter the service you want boosted"
                  value={service}
                  onChange={handleServiceChange}
                  className="w-full bg-secondary/40 text-white py-4 px-6 rounded-xl text-lg border border-primary/30 focus:border-accent focus:ring-1 focus:ring-accent outline-none transition"
                />
              </div>

              <div>
                <label className="block text-gray-300 mb-2">Payment Method</label>
                <select
                  value={paymentMethod}
                  onChange={handlePaymentMethodChange}
                  className="w-full bg-secondary/40 text-white py-4 px-6 rounded-xl text-lg border border-primary/30 focus:border-accent focus:ring-1 focus:ring-accent outline-none transition"
                >
                  <option value="" disabled>Select payment method</option>
                  <option value="paypal">PayPal</option>
                  <option value="crypto">Crypto</option>
                  <option value="applepay">Apple Pay</option>
                  <option value="cashapp">CashApp</option>
                  <option value="card">Credit/Debit Card</option>
                </select>
              </div>
            </div>

            <button
              className="w-full bg-accent hover:bg-accent-dark text-white py-4 px-8 rounded-xl text-lg font-semibold transform hover:scale-105 transition-all duration-200 shadow-lg shadow-accent/25"
              onClick={handleSubmit}
            >
              Submit Boost Request
            </button>

            {(isReporting || reportStatus) && (
              <div className="text-center">
                {isReporting && <p className="text-blue-400">Processing request...</p>}
                {reportStatus && (
                  <p className={`text-lg ${reportStatus.includes('Error') ? 'text-red-500' : 'text-green-500'}`}>
                    {reportStatus}
                  </p>
                )}
              </div>
            )}
          </section>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default AccountBoosting;