import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';

const FAQ = () => {
  return (
    <div className="flex flex-col min-h-screen bg-background">
      <Header />
      <main className="container mx-auto px-4 md:px-8 py-12 flex-grow">
        <section className="max-w-3xl mx-auto">
          <h2 className="text-4xl md:text-5xl font-bold mb-12 text-center text-indigo-400">
            Frequently Asked Questions
          </h2>
          <div className="space-y-4">
            {faqItems.map((item, index) => (
              <details 
                key={index} 
                className="group bg-gray-900/50 backdrop-blur-sm rounded-2xl border border-indigo-500/20"
              >
                <summary className="cursor-pointer p-6 font-semibold text-gray-100 flex items-center justify-between hover:text-indigo-400 transition-colors">
                  {item.question}
                  <svg 
                    className="w-5 h-5 transition-transform group-open:rotate-180" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth={2} 
                      d="M19 9l-7 7-7-7" 
                    />
                  </svg>
                </summary>
                <div className="p-6 pt-0 text-gray-300">
                  {item.answer}
                </div>
              </details>
            ))}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

const faqItems = [
  {
    question: "What is the method you use for transferring coins?",
    answer: "Our method will be revealed upon payment and it is expected that you keep it confidential to ensure your own safety."
  },
  {
    question: "What payment methods do you accept?",
    answer: "We accept Paypal, Cashapp, Crypto, Apple Pay, debit card, and visa gift cards for coin purchases and account sales."
  },
  {
    question: "How is the middleman fee paid?",
    answer: "Preferably through Crypto, Cashapp, Venmo, or Paypal, but other payment methods may also be accepted."
  },
  {
    question: "How do I buy and sell crypto?",
    answer: "First you must understand the difference between an exchange and a wallet. A wallet is for holding and an exchange is similar to a bank account, allowing you to purchase (and in most cases hold) crypto at will. Exodus is a recommended wallet and exchanges like Coinbase (which requires you to have a ID) are recommended for purchasing. If you are under 18 we offer exchanging services with a flat 10% fee through support tickets."
  }
];

export default FAQ;