import React from 'react';
import {FaDiscord, FaTelegram} from 'react-icons/fa';
import Header from './components/Header';
import Footer from './components/Footer';

const HomePage = () => {
  return (
    <div className="flex flex-col min-h-screen bg-background">
      <Header/>

      <main className="container mx-auto px-4 md:px-8 py-12 flex-grow">
        <section className="text-center mb-16">
          <h2 className="text-5xl md:text-6xl font-bold mb-6 text-indigo-400">
            Wildism Coins
          </h2>
          <p className="text-xl text-gray-300">
            Enter the Skyblock marketplace with no fear.
          </p>

          <div className="flex flex-col md:flex-row justify-center gap-4 mt-10">
            <a href="https://discord.gg/fmPqM4MWA2" target="_blank" rel="noopener noreferrer"
               className="bg-[#5865F2] hover:bg-[#4752C4] text-white py-4 px-8 rounded-xl text-lg font-semibold flex items-center justify-center gap-3 transform hover:scale-105 transition-all duration-200 shadow-lg">
              <FaDiscord size={24}/>
              Join our Discord
            </a>

            <a href="https://t.me/Wildismm" target="_blank" rel="noopener noreferrer"
               className="bg-secondary hover:bg-primary text-white py-4 px-8 rounded-xl text-lg font-semibold flex items-center justify-center gap-3 transform hover:scale-105 transition-all duration-200 shadow-lg">
              <FaTelegram size={24}/>
              Contact on Telegram
            </a>

            <a href="https://discord.gg/scammerlist" target="_blank" rel="noopener noreferrer"
               className="bg-secondary hover:bg-primary text-white py-4 px-8 rounded-xl text-lg font-semibold flex items-center justify-center gap-3 transform hover:scale-105 transition-all duration-200 shadow-lg">
              <FaDiscord size={24}/>
              Join Scammer List
            </a>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-16">
            {[
              {
                href: '/buy',
                title: 'Buy Coins',
                description: 'Find and purchase coins from our trusted sellers.'
              },
              {
                href: '/sell',
                title: 'Sell Coins',
                description: 'Sell your coins securely to our trusted buyers.'
              },
              {
                href: '/accounts',
                title: 'Skyblock Accounts',
                description: 'Buy and sell Skyblock accounts safely.'
              },
              {
                href: '/middleman',
                title: 'Request a Middleman',
                description: 'Use our middleman service for secure transactions.'
              },
              {
                href: '/boosting',
                title: 'Skyblock Boosting',
                description: 'Boost your Skyblock experience with our services.'
              },
              {
                href: '/scammerlist',
                title: 'Report a Scammer',
                description: 'View and report scammers in the Skyblock trading community.'
              }
            ].map((item, index) => (
              <a key={index} href={item.href}
   className="bg-primary/20 backdrop-blur-sm border border-primary/30 rounded-2xl p-8 flex flex-col items-center text-center group hover:bg-accent/20 transform hover:scale-105 transition-all duration-200 shadow-xl">
  <h3 className="text-3xl font-bold mb-4 text-accent group-hover:text-text-blue transition-colors">
    {item.title}
  </h3>
  <p className="text-lg text-white/80">
    {item.description}
  </p>
</a>
            ))}
          </div>
        </section>
      </main>

      <Footer/>
    </div>
  );
};

export default HomePage;