import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';

const BuyAccountPolicy = () => {
  return (
    <div className="flex flex-col min-h-screen bg-background">
      <Header />
      
      <main className="container mx-auto px-4 md:px-8 py-12 flex-grow">
        <section className="text-center mb-16">
          <h2 className="text-5xl md:text-6xl font-bold mb-6 text-indigo-400">
            Buy Account Policy
          </h2>
        </section>

        <section className="max-w-4xl mx-auto">
          <div className="bg-primary/20 backdrop-blur-sm rounded-2xl p-8 border border-primary/30 shadow-xl">
            <div className="space-y-12 text-gray-200">
              <div className="space-y-4">
                <h3 className="text-2xl font-bold text-accent">1. PayPal Payments</h3>
                <p>To ensure smooth transactions, all PayPal payments must be sent via Friends & Family (F&F). PLEASE NOTE: Goods & Services (G&S) transactions are not supported and may not be able to be refunded. If F&F is not supported in your country, you will need to use an alternative payment method.</p>
              </div>

              <div className="space-y-4">
                <h3 className="text-2xl font-bold text-accent">2. Account Acquisition</h3>
                <p>Our accounts are acquired through legitimate means and are not obtained through phishing, ratting, or duping. We work diligently to verify the source of each account to ensure its integrity. While we strive to provide the safest account experience possible, we may not always be successful in preventing those accounts from being sold to us. If you suspect that an account was obtained through illegitimate means, please report it to us immediately.</p>
              </div>

              <div className="space-y-4">
                <h3 className="text-2xl font-bold text-accent">3. Previous Sellers</h3>
                <p>Previous sellers of the account no longer retain access to it and all information regarding the account has been anonymized, as it was securely transferred to our account sellers.</p>
              </div>

              <div className="space-y-4">
                <h3 className="text-2xl font-bold text-accent">4. Refund Policy</h3>
                <p>If any unforeseen circumstances affect the account before delivery (e.g., bans or theft), you are eligible for a full refund. However, once the account has been delivered and secured, refunds will not be granted.</p>
              </div>

              <div className="space-y-4">
                <h3 className="text-2xl font-bold text-accent">5. Post-Delivery Issues</h3>
                <p>In the event of any issues arising post-delivery, provided evidence confirms that you were not at fault and that you have properly secured the account, you are eligible for a full refund or replacement. If you were unable to change the email due to a rate limit, refund eligibility remains unaffected.</p>
              </div>

              <div className="space-y-4">
                <h3 className="text-2xl font-bold text-accent">6. Fraudulent Activities</h3>
                <p>All fraudulent activities are strictly prohibited while using our services. If we receive evidence that funds or accounts distributed to us were obtained through fraudulent means, we reserve the right to deny your use of our services, confiscate any funds or accounts obtained through fraudulent means, return any fraudulently obtained funds or items to their rightful owner, provide evidence of your fraudulent activities to scammer lists, and/or take any other action we deem necessary. If you feel that you have been falsely accused of fraudulent activities, you may request a review of your case from a trusted independent third party (i.e., Scammer List).</p>
              </div>

              <div className="space-y-4">
                <h3 className="text-2xl font-bold text-accent">Further Inquiries</h3>
                <p>For further inquiries or clarification regarding our policies, please do not hesitate to reach out to us via our Discord Server.</p>
              </div>
            </div>
          </div>
        </section>
      </main>
      
      <Footer />
    </div>
  );
};

export default BuyAccountPolicy;