import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';

const TOS = () => {
  return (
    <div className="flex flex-col min-h-screen bg-background">
      <Header />
      <main className="container mx-auto px-4 md:px-8 py-12 flex-grow">
        <section className="max-w-4xl mx-auto">
          <h2 className="text-4xl md:text-5xl font-bold mb-12 text-center text-indigo-400">
            Terms of Service
          </h2>
          <div className="bg-gray-900/50 backdrop-blur-sm rounded-2xl p-6 md:p-8 border border-indigo-500/20 space-y-8">
            <div>
              <h3 className="text-2xl font-bold mb-4 text-indigo-400">Respect for All Users</h3>
              <p className="text-gray-300 leading-relaxed">You are required to exhibit respect towards all Wildism Coins users, regardless of your personal opinions towards them. You must treat others with respect and kindness and refrain from any form of bullying, harassment, or discrimination.</p>
            </div>
            
            <div>
              <h3 className="text-2xl font-bold mb-4 text-indigo-400">Prohibition of Threats</h3>
              <p className="text-gray-300 leading-relaxed">Direct or indirect threats, including those of DDoS, death, abuse, or any other malicious actions, are strictly forbidden on Wildism Coins. This encompasses both jokes and false threats.</p>
            </div>
            
            <div>
              <h3 className="text-2xl font-bold mb-4 text-indigo-400">Prohibited Content</h3>
              <p className="text-gray-300 leading-relaxed">We strictly disallow posting or distribution of any pornographic, adult, or other NSFW material on Wildism Coins. Gore or any other extremely violent or disturbing content is also expressly prohibited.</p>
            </div>
            
            <div>
              <h3 className="text-2xl font-bold mb-4 text-indigo-400">Unsolicited Messages</h3>
              <p className="text-gray-300 leading-relaxed">You may not send unsolicited messages to other users without their explicit consent. Unsolicited messages include but are not limited to promotional content, marketing material, or any form of communication that has not been expressly requested by the recipient.</p>
            </div>
            
            <div>
              <h3 className="text-2xl font-bold mb-4 text-indigo-400">Malicious Software</h3>
              <p className="text-gray-300 leading-relaxed">You must not engage in any activity related to the sale or distribution of malware, viruses, spyware, or any malicious software that can compromise the functionality of users' devices and data.</p>
            </div>
            
            <div>
              <h3 className="text-2xl font-bold mb-4 text-indigo-400">Skyblock Services</h3>
              <div className="space-y-4 text-gray-300 leading-relaxed">
                <p>
                  <span className="font-semibold">Coin Sales:</span> You are held responsible for anything that happens after your purchase. We carefully select our methods to ensure the lowest chance of incident but in the case that something happens we take no liability.
                </p>
                <p>
                  <span className="font-semibold">Account Sales:</span> You must acknowledge that buying and selling Skyblock accounts may violate the game's terms of service, and you assume all risks associated with these transactions.
                </p>
                <p>
                  <span className="font-semibold">Boosting Service:</span> By utilizing our boosting service, which involves logging into your account, you agree that Wildism Coins is not liable for any bans or penalties incurred while we are logged into your account. The risk of account bans is fully assumed by the account owner. Needless to say, we will not do anything that may cause a ban (e.g., macroing, cheating, boosting).
                </p>
              </div>
            </div>
            
            <div className="pt-4 border-t border-indigo-500/20">
              <p className="text-gray-300 leading-relaxed">
                Violation of these terms and conditions of use may result in the revocation of your access to Wildism Coins and any services/products associated with it, including but not limited to the server itself, our discord server, any virtual items, or accounts that are associated with your violation. We also reserve the right to change these terms and conditions without prior notice. Your use of Wildism Coins services constitutes your agreement to be bound by any such modifications.
              </p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default TOS;