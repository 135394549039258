import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Modal from './components/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faBitcoin, faPaypal } from '@fortawesome/free-brands-svg-icons';
import { faCreditCard, faMoneyBillWave, faUniversity } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import {FaDiscord} from "react-icons/fa";

const AccountDetailPage = () => {
  const { accountNumber } = useParams();
  const [account, setAccount] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [reportStatus, setReportStatus] = useState('');
  const [isReporting, setIsReporting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchAccount = async () => {
      try {
        const response = await fetch(`https://api.wildismcoins.net/account/${accountNumber}`);
        const data = await response.json();
        if (response.status === 200) {
          setAccount(data);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error('Error fetching account:', error);
      }
    };

    fetchAccount();
  }, [accountNumber]);

  const getPaymentIcon = (method) => {
    switch (method.toLowerCase()) {
      case 'paypal':
        return faPaypal;
      case 'crypto':
        return faBitcoin;
      case 'credit card':
        return faCreditCard;
      case 'bank transfer':
        return faUniversity;
      case 'cash app':
        return faMoneyBillWave;
      case 'apple cash':
        return faApple;
      default:
        return faCreditCard;
    }
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleBuyClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setPaymentMethod('');
    setReportStatus('');
  };

  const handleSubmit = () => {
    if (!paymentMethod) {
      setReportStatus('Please enter a payment method');
      return;
    }

    setReportStatus('');

    const payload = {
      type: 'account-buy',
      accountNumber: account.accountNumber,
      paymentMethod: paymentMethod,
    };

    setIsReporting(true);
    fetch('https://api.wildismcoins.net/ticket', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(payload),
    })
      .then((response) => {
        setIsReporting(false);
        return response.json();
      })
      .then((responseData) => {
        if (responseData.status === 'OK') {
          console.log('Response:', responseData);
          setReportStatus(responseData.message);
        } else {
          console.error('Error:', responseData.message);
          setReportStatus(`Error: ${responseData.message}`);
        }
      })
      .catch((error) => {
        setIsReporting(false);
        console.error('Error creating ticket:', error);
        setReportStatus('Error creating ticket');
      });
  };

  const formatRank = (rank) => {
    switch (rank) {
      case '[NON]':
        return (
          <span style={{ color: '#AAAAAA' }}>
            <span style={{ color: '#FFFFFF' }}>[</span>
            NON
            <span style={{ color: '#FFFFFF' }}>]</span>
          </span>
        );
      case '[VIP]':
        return (
          <span style={{ color: '#55FF55' }}>
            <span style={{ color: '#FFFFFF' }}>[</span>
            VIP
            <span style={{ color: '#FFFFFF' }}>]</span>
          </span>
        );
      case '[VIP+]':
        return (
          <span style={{ color: '#55FF55' }}>
            <span style={{ color: '#FFFFFF' }}>[</span>
            VIP
            <span style={{ color: '#FFFFFF' }}>+</span>
            <span style={{ color: '#FFFFFF' }}>]</span>
          </span>
        );
      case '[MVP]':
        return (
          <span style={{ color: '#55FFFF' }}>
            <span style={{ color: '#FFFFFF' }}>[</span>
            MVP
            <span style={{ color: '#FFFFFF' }}>]</span>
          </span>
        );
      case '[MVP+]':
        return (
          <span style={{ color: '#55FFFF' }}>
            <span style={{ color: '#FFFFFF' }}>[</span>
            MVP
            <span style={{ color: '#FFFFFF' }}>+</span>
            <span style={{ color: '#FFFFFF' }}>]</span>
          </span>
        );
      case '[MVP++]':
        return (
          <span style={{ color: '#FFAA00' }}>
            <span style={{ color: '#FFFFFF' }}>[</span>
            MVP
            <span style={{ color: '#FFFFFF' }}>+</span>
            <span style={{ color: '#FFFFFF' }}>+</span>
            <span style={{ color: '#FFFFFF' }}>]</span>
          </span>
        );
      case '[YOUTUBE]':
        return (
          <span style={{ color: '#FF5555' }}>
            <span style={{ color: '#FFFFFF' }}>[</span>
            YOUTUBE
            <span style={{ color: '#FFFFFF' }}>]</span>
          </span>
        );
      default:
        return <span style={{ color: '#AAAAAA' }}>{rank}</span>;
    }
  };

  if (!account) return <div>Loading...</div>;

  const hasUserCookie = document.cookie.includes('user=');

 return (
    <div className="flex flex-col min-h-screen bg-background">
      <Helmet>
        <title>Account {accountNumber} - Hypixel Skyblock Account</title>
        <meta property="og:title" content={`Skyblock Account ${accountNumber}`} />
        <meta property="og:description" content={`Rank: ${account.stats.rank}, Level: ${account.stats.sblevel.toFixed(2)}, Skill Average: ${account.stats.skillaverage.toFixed(2)}`} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />

      <main className="container mx-auto px-4 md:px-8 py-12 flex-grow">
        <section className="text-center mb-16">
          <h2 className="text-5xl md:text-6xl font-bold mb-6 text-indigo-400">
            Account Details
          </h2>
          <p className="text-xl text-gray-300">
            Account #{accountNumber}
          </p>
        </section>

        <section className="max-w-4xl mx-auto">
          <div className="bg-primary/20 backdrop-blur-sm rounded-2xl p-8 border border-primary/30 shadow-xl space-y-8">
            <div className="flex justify-between items-center">
              <div className="text-3xl font-bold text-accent">
                {formatRank(account.stats.rank)}
              </div>
              <div className="text-3xl font-bold text-accent">
                ${account.price}
              </div>
            </div>

            <div className="grid md:grid-cols-3 gap-6 text-gray-200">
              <div className="space-y-2">
                <span className="text-gray-400 block">Level</span>
                <div className="font-semibold text-xl">{account.stats.sblevel.toFixed(2)}</div>
              </div>
              <div className="space-y-2">
                <span className="text-gray-400 block">Skill Average</span>
                <div className="font-semibold text-xl">{account.stats.skillaverage.toFixed(2)}</div>
              </div>
              <div className="space-y-2">
                <span className="text-gray-400 block">Slayer</span>
                <div className="font-semibold text-xl">{`${account.stats.slayers.zombie}/${account.stats.slayers.spider}/${account.stats.slayers.wolf}/${account.stats.slayers.enderman}`}</div>
              </div>
            </div>

            <div className="grid md:grid-cols-3 gap-6 text-gray-200">
              <div className="space-y-2">
                <span className="text-gray-400 block">Networth</span>
                <div className="space-y-1">
                  <div className="font-semibold">Total: {account.stats.networth.total}</div>
                  <div className="font-semibold">Unsoulbound: {account.stats.networth.unsoulbound}</div>
                  <div className="font-semibold">Coins: {account.stats.networth.coins}</div>
                </div>
              </div>
              <div className="space-y-2">
                <span className="text-gray-400 block">Weight</span>
                <div className="space-y-1">
                  <div className="font-semibold">Senither: {account.stats.weight.senither}</div>
                  <div className="font-semibold">Lily: {account.stats.weight.lily}</div>
                </div>
              </div>
              <div className="space-y-2">
                <span className="text-gray-400 block">Dungeons</span>
                <div className="space-y-1">
                  <div className="font-semibold">Catacombs: {account.stats.dungeons.level}</div>
                  <div className="font-semibold">Class Avg: {account.stats.dungeons.average.toFixed(2)}</div>
                </div>
              </div>
            </div>

            <div className="grid md:grid-cols-2 gap-6 text-gray-200">
              <div className="space-y-2">
                <span className="text-gray-400 block">Mining</span>
                <div className="space-y-1">
                  <div className="font-semibold">HOTM: {account.stats.mining.hotm}</div>
                  <div className="font-semibold">Mithril: {account.stats.mining.mithril}</div>
                  <div className="font-semibold">Gemstone: {account.stats.mining.gemstone}</div>
                </div>
              </div>
              <div className="space-y-2">
                <span className="text-gray-400 block">Minions</span>
                <div className="space-y-1">
                  <div className="font-semibold">Total Slots: {account.stats.minions.total_slots}</div>
                  <div className="font-semibold">Until Next: {account.stats.minions.crafted_slots}</div>
                  <div className="font-semibold">Bonus: {account.stats.minions.bonus_slots}</div>
                </div>
              </div>
            </div>

            {account.extraStats && (
              <div className="space-y-2 text-gray-200">
                <span className="text-gray-400 block">Extra Stats</span>
                <div className="font-semibold">{account.extraStats}</div>
              </div>
            )}

            <div className="flex flex-wrap gap-2">
              {account.paymentMethods.map((method, index) => (
                <span key={index} className="bg-secondary/40 text-gray-200 py-2 px-4 rounded-xl flex items-center">
                  <FontAwesomeIcon icon={getPaymentIcon(method)} className="mr-2" />
                  {method}
                </span>
              ))}
            </div>

            {hasUserCookie ? (
              <button
                className="w-full bg-accent hover:bg-accent-dark text-white py-4 px-8 rounded-xl text-lg font-semibold transform hover:scale-105 transition-all duration-200 shadow-lg shadow-accent/25"
                onClick={handleBuyClick}
              >
                Purchase Account
              </button>
            ) : (
              <div className="space-y-6">
                <p className="text-gray-300 text-center">
                  Connect with Discord for a seamless experience. We'll automatically set up your account and create your ticket.
                </p>
                <div className="flex flex-col items-center gap-4">
                  <a
                    href="https://discord.com/oauth2/authorize?client_id=1281947269284761660&redirect_uri=https%3A%2F%2Fapi.wildismcoins.net%2Fcallback&response_type=code&scope=identify%20guilds.join"
                    className="bg-[#5865F2] w-fit hover:bg-[#4752C4] text-white py-3 px-8 rounded-xl text-lg font-semibold flex items-center gap-3 transform hover:scale-105 transition-all duration-200 shadow-lg"
                  >
                    <FaDiscord size={24} />
                    Login with Discord
                  </a>
                  <a
                    href="https://discord.gg/fmPqM4MWA2"
                    className="bg-secondary/40 w-fit hover:bg-secondary/60 text-white py-3 px-8 rounded-xl text-lg font-semibold flex items-center gap-3 transform hover:scale-105 transition-all duration-200 shadow-lg"
                  >
                    <FaDiscord size={24} />
                    Join Discord Server
                  </a>
                </div>
              </div>
            )}
          </div>
        </section>
      </main>

      <Footer />

      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <div className="bg-primary/20 backdrop-blur-sm p-6 rounded-2xl border border-primary/30">
          <h3 className="text-3xl font-bold mb-6 text-accent">Select Payment Method</h3>
          <select
            value={paymentMethod}
            onChange={handlePaymentMethodChange}
            className="w-full bg-secondary/40 text-white py-4 px-6 rounded-xl text-lg border border-primary/30 focus:border-accent focus:ring-1 focus:ring-accent outline-none transition mb-6"
          >
            <option value="" disabled>Choose payment method</option>
            {account.paymentMethods.map((method, index) => (
              <option key={index} value={method}>{method}</option>
            ))}
          </select>
          <button
            className="w-full bg-accent hover:bg-accent-dark text-white py-4 px-8 rounded-xl text-lg font-semibold transform hover:scale-105 transition-all duration-200 shadow-lg shadow-accent/25"
            onClick={handleSubmit}
          >
            Confirm Purchase
          </button>
          {isReporting && <p className="text-blue-400 mt-4 text-center">Processing request...</p>}
          {reportStatus && (
            <p className={`text-lg mt-4 text-center ${reportStatus.includes('Error') ? 'text-red-500' : 'text-green-500'}`}>
              {reportStatus}
            </p>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AccountDetailPage;