import React, { useState } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import { FaDiscord, FaSearch } from 'react-icons/fa';

const ScammerList = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [scammerData, setScammerData] = useState(null);
  const [reportStatus, setReportStatus] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchQuery.trim().length < 3) {
      setScammerData(null);
      setReportStatus('Please enter at least 3 characters.');
      return;
    }

    try {
      setIsSearching(true);
      const response = await fetch(`https://api.wildismcoins.net/scammer/lookup/${encodeURIComponent(searchQuery)}`, {
        credentials: 'include',
      });
      const data = await response.json();

      if (!response.ok) throw new Error(data.message);

      if (data.status === 'OK') {
        setScammerData(data.response);
        setReportStatus('');
      } else {
        setScammerData(null);
        setReportStatus('No scammers found for the given query.');
      }
    } catch (error) {
      console.error('Error fetching scammer data:', error);
      setScammerData(null);
      setReportStatus(`Error: ${error.message}`);
    } finally {
      setIsSearching(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-background">
      <Header />

      <main className="container mx-auto px-4 md:px-8 py-12 flex-grow">
        <section className="text-center mb-16">
          <h2 className="text-5xl md:text-6xl font-bold mb-6 text-indigo-400">
            Scammer List
          </h2>
          <p className="text-xl text-gray-300 mb-10">
            View and report scammers in the Minecraft trading community
          </p>
        </section>

        <section className="max-w-3xl mx-auto space-y-12">
          <div className="bg-primary/20 backdrop-blur-sm rounded-2xl p-8 border border-primary/30 shadow-xl">
            <h3 className="text-3xl font-bold text-accent mb-6">Search Database</h3>
            <form onSubmit={handleSearch} className="flex gap-4">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Enter username, Discord ID, or other identifiers"
                className="flex-1 bg-secondary/40 text-white py-4 px-6 rounded-xl text-lg border border-primary/30 focus:border-accent focus:ring-1 focus:ring-accent outline-none transition"
              />
              <button
                type="submit"
                disabled={isSearching}
                className="bg-accent hover:bg-accent-dark text-white py-4 px-8 rounded-xl text-lg font-semibold flex items-center gap-2 transform hover:scale-105 transition-all duration-200 shadow-lg shadow-accent/25"
              >
                <FaSearch />
                {isSearching ? 'Searching...' : 'Search'}
              </button>
            </form>
          </div>

          <div className="bg-primary/20 backdrop-blur-sm rounded-2xl p-8 border border-primary/30 shadow-xl text-center">
            <h3 className="text-3xl font-bold text-accent mb-6">Report a Scammer</h3>
            <p className="text-gray-200 mb-8">
              To report a scammer, join our official scammer list Discord server
            </p>
            <a
              href="https://discord.gg/scammerlist"
              className="bg-[#5865F2] hover:bg-[#4752C4] text-white py-3 px-8 rounded-xl text-lg font-semibold inline-flex items-center gap-3 transform hover:scale-105 transition-all duration-200 shadow-lg"
            >
              <FaDiscord size={24} />
              Join Scammer List Discord
            </a>
          </div>

          {reportStatus && (
            <div className={`text-center text-lg ${reportStatus.includes('Error') ? 'text-red-500' : 'text-green-500'}`}>
              {reportStatus}
            </div>
          )}

          {scammerData && Array.isArray(scammerData) && (
            <div className="space-y-4">
              <h3 className="text-3xl font-bold text-accent mb-6">Search Results</h3>
              {scammerData.map((scammer, index) => (
                <div key={index} className="bg-primary/20 backdrop-blur-sm rounded-2xl p-6 border border-primary/30 shadow-xl">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="space-y-2">
                      <span className="text-gray-400 block">Username</span>
                      <div className="font-semibold text-xl text-white">{scammer.tag}</div>
                    </div>
                    <div className="space-y-2">
                      <span className="text-gray-400 block">Discord ID</span>
                      <div className="font-semibold text-xl text-white">{scammer.id}</div>
                    </div>
                    <div className="space-y-2">
                      <span className="text-gray-400 block">Method</span>
                      <div className="font-semibold text-xl text-white">{scammer.method}</div>
                    </div>
                    <div className="space-y-2">
                      <span className="text-gray-400 block">Amount</span>
                      <div className="font-semibold text-xl text-white">{scammer.amount}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default ScammerList;