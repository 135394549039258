import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const TwoFAPage = () => {
  const { fasecret } = useParams();
  const [secret, setSecret] = useState(fasecret || '');
  const [code, setCode] = useState('');
  const [reportStatus, setReportStatus] = useState('');
  const [timeLeft, setTimeLeft] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (fasecret) {
      generateCode(fasecret.replace(/\s+/g, ''));
    }
  }, [fasecret]);

  useEffect(() => {
    if (secret) {
      generateCode(secret.replace(/\s+/g, ''));
      updateURL(secret.replace(/\s+/g, ''));
    }
  }, [secret]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(prevTimeLeft => {
          if (prevTimeLeft <= 1) {
            generateCode(secret.replace(/\s+/g, ''));
            clearInterval(timer);
            return 0;
          }
          return prevTimeLeft - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [timeLeft, secret]);

  const generateCode = async (secret) => {
    try {
      const cleanedSecret = secret.replace(/\s+/g, '').toUpperCase();
      if (!/^[A-Z2-7]{16}$/gi.test(cleanedSecret)) {
        setReportStatus('Invalid secret key! It must be 16 characters long, only containing A-Z and 2-7.');
        return;
      }
      const response = await fetch(`https://api.wildismcoins.net/2fa/${cleanedSecret}`);
      const data = await response.json();
      if (response.ok) {
        setCode(data.token);
        setTimeLeft(data.timeLeft);
        setReportStatus('');
      } else {
        setReportStatus(data.error || 'Error generating 2FA code.');
      }
    } catch (error) {
      setReportStatus('Error generating 2FA code.');
      console.error(error);
    }
  };

  const handleSecretChange = (e) => {
    const value = e.target.value.replace(/[^a-z0-9\s]/gi, '').toUpperCase();
    if (value.replace(/\s+/g, '').length <= 16) {
      setSecret(value);
    }
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(code);
    setReportStatus('Code copied to clipboard!');
  };

  const handleCopyLinkClick = () => {
    const cleanedSecret = secret.replace(/\s+/g, '').toUpperCase();
    const link = `https://wildismcoins.net/middleman/2fa/${cleanedSecret}`;
    navigator.clipboard.writeText(link);
    setReportStatus('Link copied to clipboard!');
  };

  const updateURL = (cleanedSecret) => {
    const newUrl = `/middleman/2fa/${cleanedSecret}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
  };

  return (
    <div className="flex flex-col min-h-screen bg-background">
      <Header />

      <main className="container mx-auto px-4 md:px-8 py-12 flex-grow">
        <section className="text-center mb-16">
          <h2 className="text-5xl md:text-6xl font-bold mb-6 text-indigo-400">
            2FA Generator
          </h2>
          <p className="text-xl text-gray-300 mb-10">
            Generate secure 2FA codes with your secret key
          </p>
        </section>

        <section className="max-w-2xl mx-auto space-y-8">
          <div className="bg-primary/20 backdrop-blur-sm rounded-2xl p-8 border border-primary/30 shadow-xl">
            <h3 className="text-2xl font-bold text-gray-200 mb-6">Enter 2FA Secret</h3>
            <input
              type="text"
              placeholder="Enter your 2FA secret"
              value={secret}
              onChange={handleSecretChange}
              className="w-full bg-secondary/40 text-white py-4 px-6 rounded-xl text-lg border border-primary/30 focus:border-accent focus:ring-1 focus:ring-accent outline-none transition mb-6"
            />
            <button
              className="w-full bg-secondary/40 hover:bg-secondary/60 text-white py-3 px-6 rounded-xl text-lg flex items-center justify-center gap-2 transform hover:scale-105 transition-all duration-200"
              onClick={handleCopyLinkClick}
            >
              <FontAwesomeIcon icon={faCopy} />
              Copy Link
            </button>
          </div>

          {code && (
            <div className="bg-primary/20 backdrop-blur-sm rounded-2xl p-8 border border-primary/30 shadow-xl text-center">
              <div className="space-y-6">
                <div>
                  <h4 className="text-xl text-gray-400 mb-2">Your 2FA Code</h4>
                  <p className="text-4xl font-bold text-accent tracking-wide">{code}</p>
                </div>
                
                <button
                  className="bg-accent hover:bg-accent-dark text-white py-3 px-8 rounded-xl text-lg font-semibold transform hover:scale-105 transition-all duration-200 shadow-lg shadow-accent/25"
                  onClick={handleCopyClick}
                >
                  Copy Code
                </button>
                
                <p className={`text-lg ${timeLeft <= 5 ? 'text-red-400' : 'text-gray-300'}`}>
                  Expires in {timeLeft} second{timeLeft !== 1 ? 's' : ''}
                </p>
              </div>
            </div>
          )}

          {reportStatus && (
            <div className={`text-center text-lg ${reportStatus.includes('Error') ? 'text-red-500' : 'text-green-500'}`}>
              {reportStatus}
            </div>
          )}
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default TwoFAPage;